<template>
  <CardConf
    :title="$t('exp_imp.title')"
    :icon="' mdi-swap-vertical'"
    :size="'450'"
    :activeted="true"
    :buttons="false"
  >
    <template>
      <v-row>
        <ExpImp ref="expImp" />
      </v-row>
      <v-row justify="center" align="center">
        <v-col
          cols="12"
          md="7"
          v-bind:class="[{ 'text-center': $vuetify.breakpoint.smAndDown }]"
          >Reset / Archivar Datos</v-col
        >
        <v-col cols="11" md="5">
          <Archivate />
          <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                style="position: absolute; right: 8px; top: 350px"
                color="red"
                @click="
                  $emit('changeTab');
                  $refs.card.dialog = false;
                "
                >mdi-information-outline</v-icon
              >
            </template>
            <span style="font-size: 12px">
              Esta funcionalidad sirve para poder resetear los campos escogidos
              y así poder empezar a contabilizar los campos desde cero.
            </span>
          </v-tooltip>
          <v-menu v-else :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                style="position: absolute; right: 20px; top: 545px"
                color="red"
                @click="
                  $emit('changeTab');
                  $refs.card.dialog = false;
                "
                >mdi-information-outline</v-icon
              >
            </template>
            <p class="py-3 mb-0 px-5" style="font-size: 12px">
              Esta funcionalidad sirve para poder resetear los campos escogidos
              y así poder empezar a contabilizar los campos desde cero.
            </p>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="7"
          v-bind:class="[{ 'text-center': $vuetify.breakpoint.smAndDown }]"
        >
          Importar citas desde Gmail
        </v-col>
        <v-col>
          <ImportFromGoogleMassive />

          <v-icon
            v-if="false"
            style="position: absolute; top: 395px; right: 8px"
            >mdi-information-outline
          </v-icon>
        </v-col>
      </v-row>
    </template>
  </CardConf>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AlertsCard",
  components: {
    ExpImp: () => import("@/components/user/settings/Exp-Imp"),
    ImportFromGoogleMassive: () =>
      import(
        "@/components/user/settings/configuracion/ImportFromGoogleMassive"
      ),
    CardConf: () => import("@/components/user/settings/Card"),
    Archivate: () =>
      import("@/components/user/settings/configuracion/archive/Dialog"),
  },

  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {},
};
</script>

